@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500;700&family=Roboto:wght@300;400;500;700;900&display=swap");

*,
html {
  /* padding: 0; */
  margin: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  line-height: 100%;
}

/* h1,
h2,
h3 {
  font-family: "Oswald", sans-serif;
} */

body {
  color: #535353;
  line-height: 32px;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

a,
button {
  text-decoration: none;
  transition: 0.5s;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 0px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #005288;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #005288;
}

.c-side-bar-w::-webkit-scrollbar {
  display: none;
}

.c-btn-1 {
  /* padding: 15px;
  border-radius: 30px;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  border: none;
  font-weight: bold;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  background-color: #253d98;
  text-decoration: none;
  padding-right: 65px;
  border: none;
  box-shadow: none;
  margin-bottom: 10px; */
  padding: 7px 15px;
  border-radius: 30px;
  /* text-transform: uppercase; */
  color: #fff;
  font-size: 15px;
  border: none;
  font-weight: normal;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  background-color: #253d98;
  text-decoration: none;
  padding-right: 42px;
  border: none;
  box-shadow: none;
  margin-bottom: 10px;
}

.c-btn-1 strong {
  position: relative;
  z-index: 2;
  transition: 0.5s;
}

.c-btn-1 span {
  /* width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #f6dd2f;
  position: relative;
  z-index: 1;
  position: absolute;
  right: 8px;
  top: 6px;
  font-size: 26px;
  padding-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #000;
  color: #000;
  transition: 0.5s;
  padding-bottom: 5px; */
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f6dd2f;
  position: relative;
  z-index: 1;
  position: absolute;
  right: 8px;
  top: 6px;
  font-size: 26px;
  /* padding-left: 2px; */
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #000;
  color: #000;
  transition: 0.5s;
  /* padding-bottom: 5px; */
  font-size: 20px;
}

.c-btn-1:after {
  background: #000;
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  transition: 0.5s;
}

.c-btn-1:hover:after {
  left: 0;
  top: 0;
}

.c-btn-1:hover strong {
  color: #fff;
}

.c-btn-1:hover span {
  background: #f6dd2f;
  color: #000;
}

/* login */
.c-leftBox-w {
  width: 50%;
  flex: 0 0 auto;
}

.c-leftBox-w img {
  height: 100vh;
  object-fit: contain;
  width: 100%;
}

.c-login-w {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
}

.c-rightBox-w {
  width: 50%;
  flex: 0 0 auto;
  background: #253d98;
  padding: 40px;
  padding-bottom: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.c-leftBox-w {
  position: relative;
}

.c-leftBox-w:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 340px;
  height: 340px;
  background: url("../images/login-bg-after.png") no-repeat;
  background-size: 100% 100%;
}

.c-leftBox-w:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 340px;
  height: 340px;
  background: url("../images/login-bg-before.png") no-repeat;
  background-size: 100% 100%;
}

.c-rightBox-w:after {
  content: "";
  display: block;
  position: absolute;
  background: url("../images/login-skylan-bg.png") no-repeat;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 171px;
  background-size: 100% 100%;
}

.c-rightBox-in h2 {
  color: #fff;
  font-size: 50px;
  text-align: center;
}

.c-rightBox-in p {
  color: #fff;
  font-size: 18px;
}

.c-rightBox-con {
  margin-bottom: 30px;
  text-align: center;
}

.c-rightBox-in {
  width: 100%;
  flex: 0 0 auto;
}

.c-rightBox-form {
  width: 500px;
  margin: 0 auto;
}

.c-rightBox-form input {
  padding: 15px;
  padding-left: 60px;
  border-radius: 30px;
  color: #253d98;
  line-height: 100%;
  font-size: 18px;
  border: 1px solid #fff;
  transition: 0.5s;
}

.c-rightBox-form input:hover {
  border-color: #f6dd2f;
}

.c-rightBox-form input::-webkit-input-placeholder {
  color: #253d98;
  text-transform: uppercase;
}

.c-rightBox-form input:-ms-input-placeholder {
  color: #253d98;
  text-transform: uppercase;
}

.c-rightBox-form input::placeholder {
  color: #253d98;
  text-transform: uppercase;
}

.c-rightBox-form .form-group {
  margin-bottom: 10px;
  position: relative;
}

.c-rightBox-form .form-group span {
  position: absolute;
  width: 35px;
  left: 16px;
  top: 8px;
}

.c-submit-btn {
  background: #f6dd2f;
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  position: relative;
  text-transform: uppercase;
  color: #000;
  font-size: 20px;
  border: none;
  font-weight: bold;
  margin-top: 30px;
  display: block;
}

.c-submit-btn span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #253d98;
  position: absolute;
  right: 0;
  top: 0;
}

.c-submit-btn {
  position: relative;
  overflow: hidden;
}

.c-submit-btn strong {
  position: relative;
  z-index: 2;
  transition: 0.5s;
}

.c-submit-btn span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #253d98;
  position: relative;
  z-index: 1;
  position: absolute;
  right: 8px;
  top: 6px;
  font-size: 26px;
  padding-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #fff;
  color: #fff;
  transition: 0.5s;
}

.c-submit-btn:after {
  background: #000;
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  transition: 0.5s;
}

.c-submit-btn:hover:after {
  left: 0;
  top: 0;
}

.c-submit-btn:hover strong {
  color: #fff;
}

.c-submit-btn:hover span {
  background: #f6dd2f;
  color: #000;
}

.c-forget-btn {
  text-align: right;
  margin-top: 15px;
}

.c-forget-btn a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.c-forget-btn a svg {
  margin-right: 5px;
  font-size: 22px;
}

.c-forget-btn a:hover {
  color: #f6dd2f;
}

/* dashboard */

.c-inner-page {
  min-height: 100vh;
  background: #eefbff;
  position: relative;
  padding-bottom: 60px;
}

.c-header-w {
  background-color: #fff;
  padding: 10px 15px;
  box-shadow: 0px -1px 19px rgba(0, 0, 0, 0.17);
  position: sticky;
  top: 0;
  z-index: 9;
}

.c-header-right ul {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.c-header-logo img {
  width: 350px;
}

.c-header-user {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.c-header-user h4 {
  color: #000000;
  font-size: 17px;
  text-transform: uppercase;
  margin: 0;
}

.c-header-user span {
  font-size: 38px;
  line-height: 100%;
  margin-right: 10px;
  position: relative;
  top: -3px;
}

.c-logout-btn button {
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  width: 38px;
}

.c-dashboard-list {
  padding: 12px 0;
}

.c-dashboard-box {
  transition: 0.5s;
  overflow: hidden;
  background: #fff;
  padding: 40px 20px 40px;
  text-align: center;
  box-shadow: 0px -1px 19px rgba(0, 0, 0, 0.17);
  position: relative;
  min-height: 237px;
}

.c-dashboard-box span {
  position: relative;
  z-index: 2;
}

.c-dashboard-box h3 {
  margin: 0;
  position: relative;
  z-index: 3;
  text-transform: uppercase;
  color: #000000;
  font-size: 22px;
  margin-top: 15px;
}

.c-dashboard-box:after {
  z-index: 1;
  content: "";
  width: 100%;
  height: 100%;
  background: url("../../assets/images/dashboard-box-1.png") no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  background-size: 100% 100%;
}

.c-dashboard-box a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.c-dashboard-box span img {
  height: 90px;
  object-fit: contain;
}

.c-dashboard-box:before {
  width: 100%;
  height: 100%;
  background: #fffce7;
  position: absolute;
  left: -100%;
  top: 0;
  content: "";
  transition: 0.5s;
}

.c-dashboard-box:hover:before {
  left: 0;
}

.c-dashboard-box:hover {
  transform: scale(1.05);
}

.c-dashboard-list .col-lg-3:nth-child(2n + 2) .c-dashboard-box:after {
  background-image: url("../../assets/images/dashboard-box-2.png");
  left: inherit;
  right: 0;
}

.c-dashboard-box {
  height: 100%;
}

.c-dashboard-list .col-lg-3 {
  margin-bottom: 30px;
}

.c-footer-w {
  position: absolute;
  background: #fff;
  padding: 15px 0;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0px -1px 19px rgba(0, 0, 0, 0.17);
}

.c-footer-w p {
  color: #000000;
  font-size: 14px;
  margin: 0;
  text-align: center;
}

/* management */

.c-inner-page-2 {
  min-height: 100vh;
  background: #eefbff;
  position: relative;
  padding-bottom: 60px;
  padding-left: 245px;
  position: relative;
}



.c-side-bar-w {
  width: 245px;
  height: 100vh vh !important;
  height: 100%;
  background-color: #253d98;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  position: fixed;
}

.c-side-bar-w ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.c-side-bar-w ul li a {
  min-height: 45px;
  display: block;
  padding: 8px 8px 8px 40px;
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  border-bottom: 2px solid #566fce;
  transition: 0.5s;
}

.c-side-bar-w ul li a span {
  position: absolute;
  left: 7px;
  top: 11px;
  /* width: 45px; */
  width: 25px;
}

.c-side-bar-w ul li a span img {
  transition: 0.5s;
}

.c-side-bar-w ul li a h4 {
  font-size: 14px;
  margin: 0;
}

.c-side-bar-w ul li a:hover,
.c-side-bar-w ul li.active a {
  background: #f6dd2f;
  color: #000;
}

.c-side-bar-w ul li.active a img,
.c-side-bar-w ul li a:hover img,
.c-side-bar-w ul li a.active img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.c-side-bar-w ul li a.active {
  background: #f6dd2f;
  color: #000;
}

.c-content-in {
  padding: 15px 20px;
}

html body .c-content-in .table-bordered th {
  border-color: #253d98;
  text-align: center;
  background: #253d98;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

html body .c-content-in .table-bordered td {
  background-color: #fff;
  border-color: #253d98;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  /* font-weight: 500; */
  vertical-align: middle;
}

html body .c-content-in .table-bordered tr {
  border-color: #253d98;
}

html body .c-content-in .table-bordered tr:nth-child(2n + 2) td {
  /* background-color: #dbdef9; */
  background-color: #f2f3fa;
}

.c-edit-btn {
  background: transparent;
  border: none;
  box-shadow: none;
  font-size: 20px;
  line-height: 100%;
  display: inline-block;
}

.c-edit-btn:hover {
  color: #253d98;
}

.c-inner-page-2 .c-footer-w {
  width: calc(100% - 245px);
  left: 245px;
}

.c-layer-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  display: none;
}

.c-sidebar-close {
  background-color: #f6dd2f;
  border-radius: 50%;
  position: fixed;
  z-index: 99;
  right: 15px;
  top: 8px;
  display: none;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border: none;
  font-size: 30px;
}

.c-menu-bar {
  display: none;
}

.c-check-box,
.c-check-box input:checked~.checkmark:after {
  display: block;
}

.c-check-box {
  margin: 0 auto;
  height: 30px;
  width: 30px;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.c-check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border: 1px solid #253d98;
  border-radius: 50%;
}

.c-check-box:hover input~.checkmark {
  background-color: #ccc;
}

.c-check-box input:checked~.checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.c-check-box .checkmark:after {
  left: 10px;
  top: 6px;
  width: 7px;
  height: 13px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.c-add-bank-btn {
  margin-bottom: 20px;
  text-align: right;
}

.c-content-in .form-group {
  margin-bottom: 30px;
}

.c-content-in .form-group .form-control::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}

.c-content-in .form-group .form-control:-ms-input-placeholder {
  color: #000;
  opacity: 1;
}

.c-content-in .form-group .form-control::placeholder {
  color: #000;
  opacity: 1;
}

.c-content-in .form-group .form-control,
.cr-autoSearch {
  height: auto;
  padding: 5px 20px;
  line-height: 100%;
  border-radius: 30px;
  border-color: #253d98;
  height: 47px;
}

.c-content-in .form-group select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("../images/select-arrow.png") no-repeat;
  background-position: 96% 50%;
}

.c-content-in .form-group .form-control:focus {
  box-shadow: none;
}

.c-content-in h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.c-btn-1.w-100 {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 47px;
  padding: 10px;
}

.c-btn-group button {
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
  display: inline-block;
}

.c-btn-group .c-del-btn {
  background-color: #fe0000;
}

.c-btn-group {
  justify-content: flex-end;
}

/* css add 5-4-2023 */
.cn-bank-search-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.cn-bank-search-btn button {
  padding: 7px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f6dd2f;
  color: #000;
  font-size: 30px;
  border: none;
  padding-left: 8px;
  transition: 0.5s;
}

.cn-bank-search-btn button:hover {
  background-color: #253d98;
  color: #fff;
}

.cn-bank-search-form {
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid#253D98;
  padding-bottom: 0;
}

.c-content-in .cn-bank-search-form .form-control {
  margin-bottom: 20px;
}

.c-content-in .cn-bank-search-form .c-btn-1 {
  width: 100%;
  justify-content: center;
  padding: 10px;
}

.c-rounded-btn {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 47px;
  border-radius: 50%;
  font-size: 25px;
  color: #fff;
  background: #253d98;
  border: none;
}

.rotate-infinite {
  animation: rotate_infinite 2s infinite linear;
}

.react-confirm-alert-overlay {
  z-index: 9999999 !important;
}

.c-content-in .cn-bank-search-form .c-cancel-btn {
  padding-right: 45px;
}

.c-content-in .cn-bank-search-form .c-cancel-btn span {
  width: 37px;
  height: 37px;
}

/* .iconlight{background-color: #0A40F7;border-radius: 50px;} */
.c-dashboard-box a {
  font-size: 0;
}

.c-dashboard-box img {
  width: 100%;
}

.c-MutualFund-text .d-flex>div {
  width: 100%;
}

@keyframes rotate_infinite {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.react-confirm-alert-overlay {
  background: hwb(227 15% 40% / 0.427) !important;
}

.react-confirm-alert-overlay button {
  background: #253d98 !important;
  padding: 10px 30px !important;
  font-size: 18px !important;
}

.react-confirm-alert-body {
  text-align: center !important;
}

.react-confirm-alert-body h1 {
  font-size: 24px;
  color: #000000;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.react-confirm-alert-body {
  color: #000 !important;
}

.disabled {
  background: #6e88eb;
}

.c-select {
  width: 100%;
  text-align: left;
}

.w-100 {
  width: 100%;
}

[id~="react-select"] {
  z-index: 9999 !important;
  opacity: 1 !important;
  background-color: #fff !important;
}

.balance-sheet-container {
  max-width: 800px;
  margin: 0 auto;
}

.balance-section {
  margin-bottom: 20px;
}

.balance-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.balance-table td,
.balance-table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.balance-table th {
  background-color: #f2f2f2;
}

.total-row {
  font-weight: bold;
}

.c-balansheet-table .table-bordered> :not(caption)>* {
  border-width: 0 1px;
}

.c-balansheet-table .table {
  border: 1px solid #253d98;
}

.c-balansheet-table td {
  background-color: #fff !important;
}

.c-balansheet-table .c-balansheet-table-parent td {
  background-color: #dbdef9 !important;
}

.c-balansheet-table .c-balansheet-table-parent td:first-child {
  text-align: center !important;
}

.c-balansheet-table td:first-child {
  text-align: right !important;
  padding-right: 10px !important;
}

.c-balansheet-table .c-balansheet-table-hw td {
  border-width: 1px;
  text-align: center;
}

.c-balansheet-table .table td {
  vertical-align: top !important;
}

html body .c-balansheet-table .c-balansheet-table-main>tr>td {
  padding: 2px !important;
}

html body .c-balansheet-table .c-balansheet-table-main .table-bordered {
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
  color: #253d98;
  text-decoration: underline;
}

html body .c-content-in .c-balansheet-table .table-bordered td:nth-child(2) {
  text-align: left;
  padding-left: 30px;
}

html body .c-content-in .c-balansheet-table .table-bordered td:nth-child(2) strong {
  padding: 0;
}

html body .c-content-in .table-bordered .c-balansheet-table-parent td:nth-child(2) {
  text-align: left;
  padding-left: 10px;
}

.p-fileupload .p-fileupload-buttonbar {
  padding: 1rem 1.25rem;
}

.p-button {
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  margin: 0;
}

.p-fileupload .p-fileupload-content {
  padding: 2rem 1rem;
}

.p-dialog .p-dialog-header {
  padding: 1rem;
}

.p-dialog .p-dialog-content {
  padding: 1rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}

.c-table-new-w {
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
}

.c-table-new-w th {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 13px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
}

.c-table-new-w td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 13px;
  line-height: normal;
}

.c-table-new-w .no-padding {
  padding: 0;
}

.c-heading-table {
  background: #92d050;
}

.no-border {
  border: none;
}

.c-table-new-w.no-padding td,
.c-table-new-w.no-padding th {
  border: none;
}

.no-border {
  border: none !important;
}

.c-heading-table-2 {
  background: #5b9bd5;
}

.c-heading-table-3 {
  background: #f4b084;
}