@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* * {
  padding: 0;
  margin: 0;
  list-style-type: none;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Exo 2', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button, button:focus, button:visited {
  outline: 0;
  box-shadow: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.lodar-wraper {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/*============= Grid systems =============*/
.conatiner {
  width: 100%;
  max-width: 1320px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  margin-right: auto;
  margin-left: auto;
}

/* ================= for login csss ============== */
.loginMain {
  width: 100%;
  height: 100vh;
}

.leftBox {
  width: 50%;
  background: #ffffff;
}

.rightBox {
  width: 50%;
  background: #61dafb;
}

.add-searchBtn .c-btn-1 {
  padding: 7px 18px;
  padding-right: 50px;
  font-weight: normal;
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-left: 3px;
}

.add-searchBtn .c-btn-1 span {
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  right: 3px;
}

.c-header-user .dropdown {
  display: flex;
  align-items: center;
}

.c-header-user .dropdown-toggle {
  padding: 0;
  background: none;
  border: none;
}

.c-header-user .dropdown-toggle::after {
  display: none;
}

.c-header-user .btn:hover,
.c-header-user .btn:focus,
.c-header-user .btn:focus-visible,
.c-header-user .btn:focus-within,
.c-header-user .btn:active {
  background: transparent;
  outline: none;
  box-shadow: none;
}

.paginator select {
  max-width: 120px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 9px;
}

.arrowBtn .pagination-btn {
  width: 34px;
  height: 34px !important;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: #253d98;
  color: #fff;
}

.arrowBtn .pagination-btn:disabled {
  background: #cccccc;
  color: #000000;
}

.arrowBtn .pagination-btn svg {
  margin-top: -4px;
}

.arrowBtn span.labelText {
  font-weight: 500;
  padding: 0;
  margin: 0 10px;
}

.c-header-user .btn:focus,
.c-header-user .btn.show {
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.c-header-user p {
  color: #000000;
  text-align: left;
  margin: 0;
}

.imgUrlBx {
  margin: 10px 0 0;
}

.iconlight {
  width: 80px;
  height: 45px;
  background: #253d98;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2rem;
}

.lightBg {
  background: #ffffff !important;
  border: 1px solid #cccccc;
}

.imgUrlBx a {
  display: block;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.colorChange:hover,
.colorChange:focus,
.colorChange:visited,
.colorChange:active {
  color: yellow;
  fill: yellow;
  cursor: pointer;
}

.c-btn-remove {
  border-radius: 50%;
  text-transform: uppercase;
  color: #fff;
  font-size: 21px;
  border: none;
  font-weight: bold;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #253d98;
  text-decoration: none;
  border: none;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
}

.c-btn-remove span {
  margin-top: -5px;
}

.c-btn-right {
  margin: 0 0 15px auto;
  display: block !important;
}

.c-add-boxes {
  border: 2px dashed #253d98;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
}

.c-btn-remove {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 17px;
  width: 35px;
  height: 35px;
  background-color: red;
}

::placeholder {
  color: rgb(109, 108, 108) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cr-uploadImgbx {
  width: 23%;
  border: 1px solid #dddddd;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.cr-deleteIcn {
  border: 0;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: red;
  position: absolute;
  top: 4px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.p-fileupload-file-thumbnail {
  margin-right: 10px;
}

.p-fileupload-row span {
  font-size: 12px;
  margin-right: 8px;
}

.p-fileupload-row .p-fileupload-file-badge {
  padding: 0 8px;
}

.cr-folderBx {
  text-align: center;
  /* min-height: 123px; */
  margin-bottom: 15px;
  transition: 0.5s ease-in-out;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 10px 0;
  position: relative;
}

.cr-folderBx img {
  width: 50px;
  margin: auto;
  display: block;
  cursor: pointer;
}

.cr-folderBx h5 {
  font-size: 13px;
  padding: 7px 0 0;
  line-height: 16px;
  cursor: pointer;
}

.cr-folderBx:hover {
  box-shadow: 0 8px 8px -4px lightblue;
}

.cr-fileList ul {
  margin: 0;
  padding: 0;
}

.cr-fileList ul li a {
  text-decoration: none;
  color: #000000;
  display: block;
  border-bottom: 1px solid #e3e3e3;
  padding: 7px 0;
}

.cr-socialIcn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.cr-socialIcn:hover,
.cr-socialIcn.active {
  background: #253d98;
  color: #ffff;
}

.cr-fileGrid {
  border: 1px solid #ebe6e6;
  text-align: center;
  padding: 7px;
  margin-bottom: 15px;
}

.cr-fileGrid img {
  margin: auto;
  display: block;
  width: 50px;
}

.cr-fileGrid h5 {
  font-size: 13px;
  padding: 7px 0 0;
  margin: 0;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem 1.5rem;
}

.p-dropdown-header .p-inputtext {
  padding: 0.5rem 0.75rem;
}

.p-dropdown-header .p-dropdown-filter-icon {
  margin-top: -.5rem;
}

ul.p-dropdown-items {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1.5rem;
}

.cr-newFormBx {
  border: 1px solid #cccccc;
  padding: 7px 7px 10px;
  border-radius: 7px;
  background: #ffffff;
  position: relative;
}

.cr-label {
  font-size: 13px;
  font-weight: 600;
}

.cr-topLabel h4 {
  font-size: 13px;
  background: #253d98;
  display: table;
  padding: 2px 13px 5px;
  border-radius: 25px;
  color: #fff;
  margin-top: -19px;
  margin-bottom: 5px;
}

.cr-newFormBx .col:nth-last-child(2) {
  margin-left: auto;
}

.cr-addonBtn {
  padding: 0;
  max-height: 41.2px;
  background: #253d98;
  color: #ffffff;
  cursor: pointer;
}

.cr-delBtn {
  width: 30px;
  height: 30px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 54%;
  right: -16px;
  transform: translateY(-54%);
  cursor: pointer;
}

h1 {
  font-size: 1.7rem !important;
}

.cr-groupBx .p-dropdown {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.c-download-pw ol {
  padding-left: 16px;
}

.c-download-pw ol li {
  margin-bottom: 10px;
  color: #495057;
}

.c-download-btn {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
  align-items: center;
}

.c-download-btn .c-download-text {
  margin-right: 30px;
}

.c-download-btn .c-excel-icon i {
  font-size: 40px;
  color: #1d6f42;
}

.c-download-btn .p-button {
  background: #253d98 !important;
  border-radius: 8px !important;
  margin-left: 8px;
}

.c-download-btn .p-button span {
  color: #fff;
}

.c-download-btn .c-download-text {
  margin-right: 30px;
}

.c-download-btn .c-excel-icon i {
  font-size: 40px;
  color: #1d6f42;
}

.p-dialog .p-dialog-header .p-dialog-title,
.p-dialog .p-dialog-header .p-dialog-title h3 {
  font-weight: 700;
  font-size: 1.25rem;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-header {
  border: none;
}

.cr-innerBody {
  background: #eefbff;
  padding: 15px;
  border: 1px solid #dedede;
  border-radius: 5px;
}

.c-download-pw h4 {
  color: #000000;
  font-size: 1.2rem;
  font-weight: 700;
}

.c-balansheet-table td.no-padding.no-padding {
  padding: 0 !important;
  border: none !important;
}

.c-profitLoss-w .cr-autoSearch {
  margin-right: .8rem !important;
}

.c-profitLoss-w .cr-autoSearch .p-dropdown-trigger {
  position: relative;
  left: 8px;
}

.c-profitLoss-w .c-btn-1 {
  padding: 10px 28px;
  padding-right: 45px;
  font-size: 18px;
}

.c-profitLoss-w .c-btn-1 span {
  top: 10px;
}



.align-team-center {
  align-items: center;
}

.p-fileupload-row .p-fileupload-file-badge {
  display: none;
}

.c-btn-1:disabled {
  opacity: 0.5;
}

.c-btn-1:disabled:after {
  display: none;
}

.c-table-new-w.no-padding tr td[style="width: 25%;"] {
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
}

.cr-BtnCus {
  min-width: 45px;
  height: 45px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  font-size: 22px !important;
  background: #253d98 !important;
  border: 1px solid #253d98 !important;
}

.cr-BtnCus span {
  margin-top: -7px;
}

.c-nav-accordian [data-pc-section="header"] {
  position: relative;
  padding: 8px 30px 8px 40px;
  cursor: pointer;
  border-bottom: 2px solid #566fce;
}

.c-nav-accordian .c-nav-accordian-btn span {
  position: absolute;
  left: 4px;
  top: 3px;
}

.c-nav-accordian [data-pc-section="icons"] {
  position: absolute;
  right: 6px;
  top: 3px;
}

.c-nav-accordian [data-pc-section="icons"] button {
  background: transparent;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: none;
}

.c-nav-accordian [data-pc-section="icons"] button {
  fill: #fff;
}

.c-nav-accordian-btn {
  font-size: 14px;
  color: #fff;
}

.c-nav-dropdown-list {
  background: #000;
}

.c-side-bar-w .c-nav-dropdown-list a {
  border-bottom: 2px solid #424242;
  position: relative;
}

.c-side-bar-w .c-nav-dropdown-list a:after {
  content: "";
  position: absolute;
  left: 17px;
  top: 50%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #566fce;
  transform: translateY(-50%);
}

.c-calender-w {
  position: relative;
}

.c-calender-w .p-inputtext {
  border: 1px solid #253d98;
  padding: 10px;
  padding-right: 45px;
  border-radius: 30px;
}

.c-calender-w .p-datepicker-trigger {
  border-radius: 50%;
  background-color: #253d98;
  width: 39px;
  height: 39px;
  position: absolute;
  right: 5px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
}

.cr-split .p-splitbutton-defaultbutton {
  border-top-right-radius: 0px;
  border-right: 1px solid #4899ef;
  border-bottom-right-radius: 0px;
}

.cr-split .p-splitbutton-menubutton {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-tieredmenu {
  padding: 0px;
}

.p-tieredmenu-root-list {
  padding: 0;
  margin: 0;
}

.p-menuitem-content a {
  gap: 10px
}

.cr-tableBx table {
  border-collapse: collapse;
  width: 100%;
}

.cr-tableBx th,
.cr-tableBx td {
  padding: 5px;
  border: 1px solid #dddddd;
}

.cr-tableBx th {
  border: 0;
}
.cr-fileList {
  position: relative;
}
.cr-fileList button {
  padding: 7px 0;
  line-height: 1;
  position: absolute;
  top: 1px;
  right: 13px;
  background: transparent;
  color: red;
  border-top: 0;
  border-right: 0;
  border-radius: 0;
  border-color: #d1d1d1;
  z-index: 1;
}