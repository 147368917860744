@media only screen and (max-width: 1399px) {
.c-dashboard-box h3 {font-size:20px;}

}    
@media only screen and (max-width: 1199px) {
    .c-rightBox-form {width: 100%;}
}
@media only screen and (max-width: 991px) {
.c-rightBox-w:after { height: 117px;}
.c-rightBox-w {padding-bottom: 130px;}
.c-header-w {text-align: center;}
.c-header-right ul {justify-content: center;margin-top:10px;}
.c-header-user h4 {font-size: 14px;}
.c-header-w {position: relative;}
.c-menu-bar { background: transparent; font-size: 34px; color: #253D98; position: absolute;right: 20px;top: 20px; 
line-height:100%; border: none; cursor: pointer; z-index: 9;}
html body .c-content-in .table-bordered td ,html body .c-content-in .table-bordered th{font-size: 13px;}
.c-edit-btn {font-size: 20px;}
.c-inner-page-2 {padding: 0;}
.c-side-bar-w {position: fixed;z-index: 99;left: -100%; transition: 0.5s;}
.c-side-bar-w.active {left: 0;}
body {overflow-x: auto;}
.c-side-bar-w.active .c-layer-bg {display: block;}
.c-side-bar-w ul {position: relative;z-index:99;}
.c-side-bar-w.active .c-sidebar-close {display: inline-flex;}
.c-inner-page-2 .c-footer-w { width:100%;left: 0;position: fixed;}
html body .c-content-in .table-bordered th {white-space: nowrap;}
.c-menu-bar {display: block;}
}
@media only screen and (max-width: 767px) {
.c-leftBox-w ,.c-rightBox-w {width: 100%;}
.c-leftBox-w:before ,.c-leftBox-w:after {width: 200px; height: 200px;}
.c-leftBox-w { display: none;}
.c-rightBox-in p { font-size: 16px;}
.c-footer-w p {font-size: 14px;}
.c-header-user h4 {font-size:14px;}
.c-header-logo img {width: 210px;}
.c-menu-bar {top: 0px; font-size: 40px;}
html body .c-content-in .table-bordered td  {white-space: nowrap;}
.c-inner-page-2  .c-header-logo {text-align: left;}
.c-side-bar-w { width: 240px;}
}